import { Text } from '@teamsparta/design-system';
import { type ReactNode, useRef } from 'react';

import { useIsomorphicLayoutEffect } from '@/hooks/common';

import * as S from './style';

interface AccordionProps {
  title: string;
  content: ReactNode;
  open?: boolean;
  height?: number;
  hasProgressBar?: boolean;
  onClick?: () => void;
}

export function Accordion({
  content,
  height,
  title,
  open,
  onClick,
  hasProgressBar,
}: AccordionProps) {
  const contentRef = useRef<HTMLDivElement>(null);

  useIsomorphicLayoutEffect(() => {
    contentRef.current.setAttribute(
      'style',
      `--height: ${contentRef.current.scrollHeight}px`,
    );
  }, []);

  return (
    <S.AccordionContainer aria-expanded={open ? 'true' : 'false'}>
      {hasProgressBar ? <S.ProgressBar active={open} /> : null}
      <S.AccordionContent open={open}>
        <S.AccordionTitleWrapper>
          <Text
            as='h3'
            mFont='mTitle2'
            wFont='wTitle2'
            color='neutralDay.white'
            onClick={onClick}
          >
            {title}
          </Text>
        </S.AccordionTitleWrapper>
        <S.AccordionContentWrapper
          ref={contentRef}
          open={open}
          $height={height}
        >
          <Text as='p' mFont='mBody4' wFont='wBody4' color='neutralDay.gray60'>
            {content}
          </Text>
        </S.AccordionContentWrapper>
      </S.AccordionContent>
    </S.AccordionContainer>
  );
}
