export const differenceData: Array<{
  title: string;
  content: string;
  imageUrl: string;
}> = [
  {
    //** CDN 이미지 연동 -> public 이미지로 임시 처리 */
    imageUrl: '/images/hub/differences-div-mobile-1.png',
    title: '10년이 지나도 남는 커리큘럼',
    content:
      '빠르게 변화하는 세상 속에서 끝까지 생존하는 개발자는 탄탄한 기본기로 지속 발전할 수 있어야 합니다. 항해는 새로운 기술을 잘 흡수하는 기본을 만들어 드리는 데에 집중합니다.',
  },
  {
    imageUrl: '/images/hub/differences-div-mobile-2.png',
    title: '끊임없이 자극을 주는 커뮤니티',
    content:
      '수료 후에도 지속적으로 피어 리뷰, 학술 행사, 네트워킹 파티 등에 초대합니다. 항해를 경험한 동료들의 수준 높은 성과를 보고 자극받으며 끈기를 놓지 않고 성장을 향해 노력하게 됩니다.',
  },
  {
    imageUrl: '/images/hub/differences-div-mobile-3.png',
    title: '진정성 있는 코칭과 집중 관리',
    content:
      '기술 매니저의 특강부터 운영 매니저의 멘탈 케어, 시니어 코치의 1:1 멘토링까지. 지속적인 지원과 격려로 도전을 지속할 수 있는 힘을 주며, 어려운 상황에서도 끈기를 이어가게 합니다.',
  },
];
