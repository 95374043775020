import { useAtom } from 'jotai';
import Head from 'next/head';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import ChannelTalkBtn from '@/components/common/ChannelTalkBtn';
import { BeusableScript } from '@/lib/sdk/beusable';
import { sendHubPageViewLog } from '@/lib/utils/businessLogics/logs/landing';
import { processUrl } from '@/lib/utils/businessLogics/utils/_helper';
import { getOnlineUserIdFromServerCookie } from '@/lib/utils/cookie';
import HubPageTemplate from '@/pages/hub/template';
import { isLoginModalShowAtom, nextPageAfterLoginAtom } from '@/store/login';
import { NextApiRequest } from 'next';

export default function Home({
  onlineUserId,
}: {
  onlineUserId: string;
  resolvedUrl: string;
}) {
  const router = useRouter();
  const [, setIsLoginModalShow] = useAtom(isLoginModalShowAtom);
  const [, setNextPageAfterLogin] = useAtom(nextPageAfterLoginAtom);
  const searchParams = useSearchParams();
  const nextPage = searchParams?.get('next');

  useEffect(() => {
    if (nextPage && !onlineUserId) {
      setIsLoginModalShow(true);
      setNextPageAfterLogin(nextPage);
      router.push('/');
    }
  }, [
    nextPage,
    onlineUserId,
    router,
    setIsLoginModalShow,
    setNextPageAfterLogin,
  ]);

  useEffect(() => {
    sendHubPageViewLog();
  }, []);

  return (
    <>
      <Head>
        <meta name='author' content='스파르타코딩클럽' />
        <meta
          name='description'
          content='현직 개발자의 생생한 후기들이 증명합니다. 개발자 커리어 개척캠프 항해99. 끈기를 기르고 결국 해내는 개발자로 성장하세요.'
        />
        <meta
          name='keywords'
          content='코딩 부트캠프, 주니어 개발자, 웹개발, 코딩공부, 프로그래밍, 파이썬, 코딩학원, 코딩 스터디, 부트캠프, 개발자, 코딩, 개발자 취업, AWS, 웹서버, 서버, 클라이언트, 자바스크립트, 리액트, React, Spring, 스프링, 앱개발, React Native, 프론트엔드, 백엔드, 풀스택, AI 개발자, LLM, 개발자 이직, 개발자 물경력, 개발자 멘토링, TDD'
        />
        <meta name='robots' content='index, follow' />
        <meta name='googlebot' content='index, follow' />
        <meta
          name='naver-site-verification'
          content='1136f21cf1c34f7ea97ea47922288c23ec478634'
        />
        <meta name='yeti' content='index, follow' />
        <meta
          name='viewport'
          content='user-scalable=no,width=device-width,initial-scale=1,shrink-to-fit=no'
        />
        <meta
          property='og:url'
          content={`${process.env.NEXT_PUBLIC_PAGE_URL}`}
        />
        <meta
          property='og:title'
          content='개발자 커리어 개척 캠프 항해99, 첫 취업부터 현직자 역량 강화까지'
        />
        <meta property='og:image' content='/images/main/og.png' />
        <meta
          property='og:description'
          content='10년이 지나도 남는 커리큘럼을 바탕으로 커리어를 개척하세요. 진정성있는 멘토링과 2천 명이 넘는 끈끈한 커뮤니티가 여러분과 함께 합니다.'
        />
        <meta property='og:site_name' content='항해99' />
        <meta property='og:type' content='website' />
        <meta property='og:locale' content='ko_KR' />
      </Head>
      <HubPageTemplate />
      <ChannelTalkBtn bottom={15} />
      <BeusableScript />
    </>
  );
}

export async function getServerSideProps({
  req,
  resolvedUrl,
}: {
  req: NextApiRequest;
  resolvedUrl: string;
}) {
  const processed = processUrl(req);
  if (processed) {
    return processed;
  }
  const onlineUserId = getOnlineUserIdFromServerCookie(req);
  return {
    props: {
      onlineUserId,
      resolvedUrl,
    },
  };
}
