import { Text } from '@teamsparta/design-system';
import Image from 'next/image';

import { isEmptyArray } from '@/lib/utils/array';

import { Banner } from './components/Banner';
import { CourseCardList } from './components/CourseCardList';
import { useHubBanner, useHubCourseCards } from './logic';
import * as S from './style';

export default function HubHeroTemplate() {
  const { banners } = useHubBanner();
  const { courseCards } = useHubCourseCards();

  return (
    <S.Container>
      <S.HubImageWrapper>
        <Image
          src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/hanghae99/hub-bg.png`}
          alt='허브 배경 이미지'
          layout='fill'
          objectFit='cover'
        />
      </S.HubImageWrapper>
      <S.ContentContainer>
        <S.TopContent>
          <S.HeroTitleContainer>
            <Text
              as='p'
              mFont='mBody1'
              wFont='wTitle4'
              color='neutralDay.gray60'
            >
              결국 해내는 개발자로.
            </Text>
            <Text
              as='h1'
              mFont='mHeader1'
              wFont='wHeader1'
              color='neutralDay.white'
            >
              개발자 커리어 개척 캠프, 항해99
            </Text>
          </S.HeroTitleContainer>
          {isEmptyArray(courseCards) ? (
            <CourseCardList.Skeleton />
          ) : (
            <CourseCardList courseCards={courseCards} />
          )}
        </S.TopContent>
        {isEmptyArray(banners) ? (
          <Banner.Skeleton />
        ) : (
          <Banner banners={banners} />
        )}
      </S.ContentContainer>
    </S.Container>
  );
}
