import Footer from '@/components/common/Footer';

import * as C from './components';
import * as S from './style';
import Gnb from '@/components/gnb/GNB/Gnb';

export default function HubPageTemplate() {
  return (
    <S.Container>
      <Gnb />
      <C.HubHero />
      <C.HubDifference />
      <C.HubReview />
      <C.HubStory />
      <C.HubCourses />
      <Footer />
    </S.Container>
  );
}
