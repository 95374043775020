import styled from '@emotion/styled';
import { neutralDay } from '@teamsparta/design-system';

import { DESKTOP } from '@/styles/themes';

export const PostCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 260px;
  cursor: pointer;
`;

export const PostCardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PostCardImageWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 260/130;
  border-radius: 8px;
  border: 1px solid ${neutralDay.gray90};
  overflow: hidden;

  ${DESKTOP} {
    border-radius: 8px;
  }
`;
