import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { hanghae, neutralDay } from '@teamsparta/design-system';

import { DESKTOP } from '@/styles/themes';
import { getVar } from '@/styles/utils/getVar';

const progressIncrease = keyframes({
  from: { height: '0%' },
  to: { height: '100%' },
});

export const ProgressBar = styled.div<{
  active: boolean;
}>`
  position: relative;
  border-radius: 90px;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
  height: 100%;
  background-color: ${neutralDay.gray90};
  width: 2px;
  flex-shrink: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: ${({ active }) => (active ? hanghae.red100 : '')};
    animation: ${({ active }) => (active ? progressIncrease : 'none')} 4.5s
      linear;
  }
`;

export const AccordionContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const AccordionTitleWrapper = styled.div`
  cursor: pointer;
`;

export const AccordionContentWrapper = styled.div<{
  open?: boolean;
  $height?: number;
}>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: ${({ open }) => (open ? getVar('--height') : '0px')};
  opacity: ${({ open }) => (open ? '1' : '0')};
  height: 100%;
  transition:
    height 0.5s ease-in-out,
    max-height 0.5s ease-in-out,
    opacity 0.2s ease-in-out;
  overflow: hidden;

  ${DESKTOP} {
    width: 340px;
  }
`;

export const AccordionContent = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ open }) => (open ? '8px' : '0')};
  transition: gap 0.5s ease-in-out;

  ${DESKTOP} {
    gap: ${({ open }) => (open ? '10px' : '0')};
  }
`;
