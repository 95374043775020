export const editorPost: Array<{
  title: string;
  description: string;
  imageUrl: string;
  link: string;
}> = [
  {
    //** CDN 이미지 연동 -> public 이미지로 임시 처리 */
    title: '개발자가 AI를 배워야 하는 이유',
    description:
      'AI 산업의 급성장 속에서 개발자들이 AI를 학습해야 하는 이유는 무엇일까요? GS 그룹 AI R&D 소속의 개발자에게 그 필요성을 물었습니다.',
    imageUrl: '/images/hub/hub_post_1.png',
    link: 'https://hanghae99.spartacodingclub.kr/blog/%EA%B0%9C%EB%B0%9C%EC%9E%90%EA%B0%80-ai%EB%A5%BC-%EB%B0%B0%EC%9B%8C%EC%95%BC-%ED%95%98%EB%8A%94-%EC%9D%B4%EC%9C%A0-26739',
  },
  {
    title: '궁금할 법한 개발자 이력서 질문 7가지',
    description:
      '서류에서 계속 탈락한다면 학력, 경력의 문제가 아닐 수 있습니다. 현직 테크 리드가 알려주는 개발자 이력서 쓰는 법.',
    imageUrl: '/images/hub/hub_post_2.png',
    link: 'https://hanghae99.spartacodingclub.kr/blog/%ED%95%A9%EA%B2%A9%ED%95%98%EB%8A%94-%EC%A3%BC%EB%8B%88%EC%96%B4-%EA%B0%9C%EB%B0%9C%EC%9E%90-%EC%9D%B4%EB%A0%A5%EC%84%9C-%EC%93%B0%EB%8A%94-%EB%B2%95-17571',
  },
  {
    title: '취업률 92%, 항해99 취업지원 프로그램',
    description:
      '수천 명의 개발자를 취업시킨 항해99만의 특별한 취업 지원 프로그램을 소개해드립니다.',
    imageUrl: '/images/hub/hub_post_3.png',
    link: 'https://hanghae99.spartacodingclub.kr/blog/%EC%B7%A8%EC%97%85%EB%A5%A0-92-%ED%95%AD%ED%95%B499%EC%9D%98-%EC%B7%A8%EC%97%85%EC%A7%80%EC%9B%90-%ED%94%84%EB%A1%9C%EA%B7%B8%EB%9E%A8-3141',
  },
];
