import { neutralDay, Text } from '@teamsparta/design-system';
import Image from 'next/image';

import { type ICourseCard } from '@/pages/hub/components/Hero/data';
import { applyOpacityToHexColor } from '@/styles/utils/applyOpacityToHexColor';

import * as S from './style';

type HeroCourseCardProps = ICourseCard;

export function CourseCard({
  title,
  subTitle,
  badge,
  imageUrl,
}: HeroCourseCardProps) {
  return (
    <S.CardContainer>
      <S.CardContentContainer>
        <S.CardTitleContainer>
          <Text
            as='h4'
            mFont='mCaption1'
            wFont='wBody2'
            color='neutralDay.gray60'
          >
            {subTitle}
          </Text>
          <Text
            as='h3'
            mFont='mTitle2'
            wFont='wTitle1'
            color='neutralDay.white'
          >
            {title}
          </Text>
        </S.CardTitleContainer>
        {badge ? (
          <S.CardBadge
            backgroundColor={
              badge.backgroundColor ?? applyOpacityToHexColor(badge.color, 0.12)
            }
          >
            <Text
              as='span'
              mFont='mCaption3'
              wFont='wCaption1'
              color={badge.color}
            >
              {badge.text}
            </Text>
          </S.CardBadge>
        ) : null}
      </S.CardContentContainer>
      <Image
        src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}${imageUrl}`}
        alt={title}
        layout='fill'
      />
    </S.CardContainer>
  );
}

CourseCard.Skeleton = CardSkeleton;

function CardSkeleton() {
  return (
    <S.CardContainer>
      <S.CardContentContainer>
        <S.CardTitleContainer>
          <Text as='h4' mFont='mCaption1'>
            &nbsp;
          </Text>
          <Text
            as='h3'
            mFont='mTitle2'
            style={{
              backgroundColor: neutralDay.gray90,
              borderRadius: '6px',
            }}
          >
            &nbsp;
          </Text>
        </S.CardTitleContainer>
        <S.CardBadge
          backgroundColor={neutralDay.gray90}
          style={{ minWidth: '80px' }}
        >
          <Text as='span' mFont='mCaption3' wFont='wCaption1'>
            &nbsp;
          </Text>
        </S.CardBadge>
      </S.CardContentContainer>
    </S.CardContainer>
  );
}
