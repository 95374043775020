import { Text } from '@teamsparta/design-system';
import { Flex } from '@teamsparta/design-system';
import Image from 'next/image';

import { LoggingClick } from '@/components/common/LoggingClick';
import { useIntersectionObserver } from '@/hooks/common';
import { useIsDesktop } from '@/hooks/common/useIsDesktop';

import { Accordion } from './components/Accordion';
import { MobileDifferenceCardList } from './components/MobileDifferenceCardList';
import { useDifference } from './logic';
import * as S from './style';

export default function DifferencesTemplate() {
  const isDesktop = useIsDesktop();
  const { activeIndex, startTimer, onClick, data } = useDifference();
  const containerRef = useIntersectionObserver<HTMLDivElement>({
    onIntersect: startTimer,
  });

  return (
    <S.Wrapper ref={containerRef}>
      <S.Container>
        <Text
          as='h2'
          mFont='mHeader1'
          wFont='wHeader2'
          color='neutralDay.white'
        >
          결국 무엇이든 해내는 개발자로
          <br />
          성장시키는 항해만의 비결
        </Text>

        <S.ContentContainer>
          <S.DesktopOnly>
            <S.ImageWrapper>
              <Image
                //** CDN 이미지 연동 public 이미지로 임시 처리 */
                // src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}${data[activeIndex].imageUrl}`}
                src={`/images/hub/differences-div-desktop-${
                  activeIndex + 1
                }.png`}
                alt=''
                fill
              />
            </S.ImageWrapper>
          </S.DesktopOnly>

          <S.MobileOnly>
            <MobileDifferenceCardList
              key={String(isDesktop)}
              differences={data}
            />
          </S.MobileOnly>

          <S.DesktopOnly key={String(isDesktop)}>
            <Flex.Column justify='center' gap={32}>
              {data.map(({ content, title }, index) => (
                <LoggingClick
                  key={title}
                  logName='hh_hub_FeatureDiv_click'
                  data={{
                    feature: title,
                  }}
                >
                  <Accordion
                    title={title}
                    content={content}
                    open={index === activeIndex}
                    onClick={() => onClick(index)}
                    hasProgressBar
                  />
                </LoggingClick>
              ))}
            </Flex.Column>
          </S.DesktopOnly>
        </S.ContentContainer>
      </S.Container>
    </S.Wrapper>
  );
}
