import { Text } from '@teamsparta/design-system';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReviewCard } from './components/ReviewCard';
import { StatisticsDisplay } from './components/Statistics';
import { reviews, statistics } from './data';
import * as S from './style';

export default function HubReviewTemplate() {
  return (
    <S.Wrapper>
      <S.Container>
        <S.ReviewVideoWrapper>
          <S.ReviewVideo
            src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/hanghae99/hub_students.mp4`}
            autoPlay
            muted
            loop
            playsInline
          />
        </S.ReviewVideoWrapper>
        <S.ReviewTopContainer>
          <Text
            as='h2'
            mFont='mHeader1'
            wFont='wHeader2'
            color='neutralDay.white'
          >
            항해를 통해 인생을 바꾼
            <br />
            수천 개의 목소리가 증명합니다.
          </Text>
          <S.StatisticsContainer>
            {statistics.map(({ title, increase }) => (
              <StatisticsDisplay
                key={title}
                title={title}
                increase={increase}
              />
            ))}
          </S.StatisticsContainer>
        </S.ReviewTopContainer>
        <S.DesktopOnly>
          <S.ReviewWrapper>
            <Swiper
              slidesPerView='auto'
              spaceBetween={20}
              style={{ width: '100%' }}
            >
              {reviews?.map(({ author, career, title, contents, course }) => (
                <SwiperSlide key={title}>
                  <ReviewCard
                    author={author}
                    career={career}
                    title={title}
                    contents={contents}
                    course={course}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </S.ReviewWrapper>
        </S.DesktopOnly>

        <S.MobileOnly>
          <S.ReviewWrapper>
            {reviews?.map(({ author, career, title, contents, course }) => (
              <ReviewCard
                key={title}
                author={author}
                career={career}
                title={title}
                contents={contents}
                course={course}
              />
            ))}
          </S.ReviewWrapper>
        </S.MobileOnly>
      </S.Container>
    </S.Wrapper>
  );
}
