import styled from '@emotion/styled';

import { DESKTOP } from '@/styles/themes';

export const CardListContainer = styled.div`
  display: flex;
  gap: 16px;

  .swiper {
    overflow: visible !important;
  }

  .swiper-slide {
    width: 148px;
  }

  ${DESKTOP} {
    gap: 20px;
    .swiper-slide {
      width: 222px;
    }
  }
`;

export const CardListWrapper = styled.div`
  overflow: visible;
  width: 100%;

  .swiper-slide {
    margin-right: 16px;
  }

  ${DESKTOP} {
    .swiper-slide {
      margin-right: 20px;
    }
  }
`;
