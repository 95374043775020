export const statistics: Array<{
  title: string;
  increase: number;
}> = [
  {
    title: '누적 수료생 (명)',
    increase: 2000,
  },
  {
    title: '연봉 상승률 (%)',
    increase: 18,
  },
  {
    title: '누적 멘토 수 (명)',
    increase: 3650,
  },
];

export const reviews: Array<{
  title: string;
  contents: string[];
  author: string;
  career: string;
  course: string;
}> = [
  {
    title:
      '부트캠프 이력서는 다 똑같다 생각했는데\n항해99 수료생은 다르다는 평가를 받았어요.',
    contents: [
      '학교에서 배운 기초 지식을 넘어 현업과 관련된 프로젝트를 진행하고 싶었어요. 최대한 빠르게, 완성도 높은 프로젝트를 얻을 수 있는 곳을 고민하다 항해99를 선택했습니다. 실제로 99일에 완성한 것이라고 믿을 수 없을 만큼 만족스러운 프로젝트를 진행했어요.',
      '개발한 서비스를 클라우드 인프라에 배포한 경험은 취업에 정말 도움이 많이 됐어요. 학교에서 과제로 하는 프로젝트에서는 경험할 수 없었던 것들이죠.',
      '실제로 당시 저를 뽑아주셨던 선배께서 “부트캠프 수료생 이력서는 다 똑같다고 생각했는데, 회사의 기술 스택 및 컬쳐핏이 잘 맞을 것 같다는 생각이 들었다”고 채용 이유를 말씀해주셨어요. 짧은 기간에 대용량 트랜잭션을 견뎌내는 프로젝트나 DB 쿼리 튜닝 경험 등 프로젝트의 덕분이죠.',
    ],
    author: '김**',
    career: '항해99 수료 후 연봉 30% 올려 이직에 성공',
    course: '개발자 코스',
  },
  {
    title: '초봉 대비 50% 높은 연봉으로\n이직에 성공했어요.',
    contents: [
      '수료 직후 이직에 성공해서 직전 연봉 대비 20% 인상되었어요. 초봉에 비하면 50% 오른 수준입니다. 항해 플러스에서 새로운 개념과 다양한 의견들을 접하게 되면서 시야가 더 넓어진 것 같아요. 기술 면접에서 논리적으로 이야기할 거리가 많아졌습니다.',
      '현업의 반복되는 레퍼토리와 개발 문화에 동화되어 있었는데, 처음으로 회사 밖 다른 개발자와 협업해보면서 마음 맞는 동료도 만나게 되어 좋았습니다. 더불어 시니어 코치님께서 이력서를 수 십 번 피드백 주신 덕분에 수료 이후에는 빅테크 기업을 포함해 서류 전형에서 떨어져 본 적 없습니다.',
    ],
    author: '금*석',
    career: '플러스 백엔드 코스 1기 수료 직후 이직',
    course: '플러스 백엔드 코스',
  },
  {
    title: '마구잡이로 쌓아왔던 커리어를\n더 반짝이게 해주는 과정이에요.',
    contents: [
      '주변에 아는 개발자가 한 명도 없었는데, 기술적으로 깊은 대화를 나눌 동료들이 많아졌어요. 합류 전엔 제가 잘하고 있는지 의문이 있었고, 제 연차와 실력이면 어느 정도인지 알고 싶었죠.',
      '합류 후 10주간 관심 있게 파 본 주제에 대해 발표하기도 하고, 마음 맞는 동기들과 팀을 이뤄 오픈소스 프로젝트까지 주도적으로 진행했습니다. 너무 좋은 분들이 많아서 인연을 계속 이어나가고 싶어요.',
      '특히 많은 개발자분들을 만나며 제가 어떤 부분을 잘하고 있는지, 어떤 부분이 부족한지 알게 되어 실력을 가늠할 수 있었습니다. 항해 플러스는 마구잡이로 쌓아왔던 커리어를 조금 더 반짝이게 해주는 과정이라고 생각해요.',
    ],
    author: '정*훈',
    career: '3년 차 프론트엔드 개발자',
    course: '플러스 프론트엔드 코스',
  },
];
