import Image from 'next/legacy/image';

import channeltalk from '/public/images/icons/channel_talk_btn.png';
import { sendLog } from '@/lib/utils/send-log';

import * as S from './style';

interface ChannelTalkBtnProps {
  bottom?: number;
  onClick?: () => void;
}
export default function ChannelTalkBtn({
  bottom = 76,
  onClick,
}: ChannelTalkBtnProps) {
  function handleClick() {
    sendLog('cht_cta_click', {});
    onClick?.();
  }

  return (
    <S.ChannelTalkButton
      bottom={bottom}
      id='channel-button'
      onClick={handleClick}
    >
      <Image
        src={channeltalk}
        layout='fill'
        objectFit='contain'
        alt='채널톡 버튼'
      />
    </S.ChannelTalkButton>
  );
}
