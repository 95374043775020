import { useQuery } from '@tanstack/react-query';

import { type CarouselTypes, getAllCarousels } from '@/api/common/carousel';

const carouselQueryKeys = {
  all: ['carousel'],
  carouselByLocation: (location: keyof CarouselTypes) => [
    ...carouselQueryKeys.all,
    location,
  ],
};

export function useGetRemoteCarousel<L extends keyof CarouselTypes>({
  location,
}: {
  location: L;
}) {
  return useQuery({
    queryKey: carouselQueryKeys.carouselByLocation(location),
    queryFn: () => getAllCarousels({ location }),
    staleTime: Infinity,
    gcTime: Infinity,
    select: (data) => data.filter((carousel) => carousel.show),
  });
}
