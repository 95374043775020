import { useCustomLoadableRemoteConfig } from '@/hooks/common';
import { useGetRemoteCarousel } from '@/hooks/react-query/common/carousel';

import { type IBanner, type ICourseCard } from './data';

export function useHubBanner(): { banners: IBanner[] } {
  const { data, isLoading, isError } = useGetRemoteCarousel({
    location: 'hub',
  });

  if (isLoading || isError) {
    return { banners: [] };
  }

  const banners: IBanner[] = data?.map((banner) => ({
    title: banner.title,
    subTitle: banner.subTitle,
    backgroundColor: banner.backgroundColor,
    desktopImageUrl: banner.desktopImage,
    mobileImageUrl: banner.mobileImage,
    link: banner.url,
  }));

  return {
    banners,
  };
}

export function useHubCourseCards() {
  const courseCards = useCustomLoadableRemoteConfig<{ cards: ICourseCard[] }>(
    'hh_hub_course_card',
  );

  if (!courseCards) {
    return { courseCards: [] };
  }

  return {
    courseCards: courseCards.cards,
  };
}
