import { Text } from '@teamsparta/design-system';
import Image from 'next/image';

import * as S from './style';

interface PostCardProps {
  title: string;
  description: string;
  imageUrl: string;
  link: string;
}

export function PostCard({ title, description, imageUrl }: PostCardProps) {
  return (
    <S.PostCardContainer>
      <S.PostCardImageWrapper>
        <Image
          //** CDN 이미지 연동 -> public 이미지로 임시 처리 */
          // src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}${imageUrl}`}
          src={imageUrl}
          alt={title}
          fill
        />
      </S.PostCardImageWrapper>
      <S.PostCardContentContainer>
        <Text as='h4' mFont='mBody1' wFont='wBody1' color='neutralDay.white'>
          {title}
        </Text>
        <Text as='p' mFont='mBody4' wFont='wBody4' color='neutralDay.gray60'>
          {description}
        </Text>
      </S.PostCardContentContainer>
    </S.PostCardContainer>
  );
}
