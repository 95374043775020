/**
 * @deprecated @teamsparta/utils의 isEmptyArray를 사용해주세요.
 */
export function isEmptyArray<T>(array: T[]) {
  return Array.isArray(array) && array.length === 0;
}

/**
 * @deprecated @teamsparta/utils의 isLastIndex를 사용해주세요.
 */
export function isLastIndex<T>(index: number, array: T[]) {
  return index === array.length - 1;
}

/**
 * @deprecated @teamsparta/utils의 getLastItem을 사용해주세요.
 */
export function getLastItem<T>(array: T[]): T | undefined {
  return array.at(-1);
}

/**
 * 시작 인덱스를 기준으로 배열을 재정렬합니다.
 * @param array 재정렬할 배열
 * @param startIndex 시작 인덱스
 */
export function reorderArrayFromIndex<T>(array: T[], startIndex: number) {
  const arrayCopy = [...array];

  const afterStart = arrayCopy.slice(startIndex);

  const beforeStart = arrayCopy.slice(0, startIndex);

  return afterStart.concat(beforeStart);
}

export function getKeyByValue(
  object: Record<string, string>,
  value: string,
): string | undefined {
  return Object.keys(object).find((key) => object[key] === value);
}

/**
 * 배열을 잘라서 반환합니다.
 * @param target 잘라낼 배열
 * @param indices 잘라낼 인덱스, 인덱스는 오름차순으로 정렬되어야 합니다.
 * @returns 잘라낸 배열
 *
 * @example
 * split([1, 2, 3, 4, 5], [1, 3]) => [[1], [2, 3], [4, 5]]
 */
export function split<T, A extends readonly number[]>(
  target: T[],
  indices: A,
): T[][] {
  const result: any[] = [];
  let prevIndex = 0;

  for (const index of indices) {
    if (index >= 0 && index <= target.length) {
      result.push(target.slice(prevIndex, index));
      prevIndex = index;
    }
  }

  result.push(target.slice(prevIndex));

  return result;
}

/**
 * 배열을 생성합니다.
 * @param length 배열의 길이
 * @param callback 콜백 함수, 함수의 반환값이 배열의 값으로 사용됩니다.
 * @returns 생성된 배열
 */
export function generateArray<T>(
  length: number,
  callback?: (index: number) => T,
): T[] {
  return Array.from({ length }, (_, index) => callback?.(index)) as T[];
}
