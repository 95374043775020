import 'swiper/css';

import {
  ArrowChevronLeftSmall,
  ArrowChevronRightSmall,
  neutralDay,
  Text,
} from '@teamsparta/design-system';
import Image from 'next/image';
import { useState } from 'react';
import SwiperCore from 'swiper';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperClass } from 'swiper/types';

import CustomLink from '@/components/common/CustomLink';
import { LoggingClick } from '@/components/common/LoggingClick';
import { Flex } from '@teamsparta/design-system';
import { useIsomorphicLayoutEffect, useResizeObserver } from '@/hooks/common';
import { IBanner } from '@/pages/hub/components/Hero/data';
import { applyOpacityToHexColor } from '@/styles/utils/applyOpacityToHexColor';
import { convertNewlineToJSX } from '@/lib/utils/convertNewLineToJSX';

import * as S from './style';

interface BannerProps {
  banners: IBanner[];
}

export function Banner({ banners }: BannerProps) {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  const containerRef = useResizeObserver({
    onResize: (entry) => {
      setContainerWidth(entry.contentRect.width);
    },
  });

  useIsomorphicLayoutEffect(() => {
    setContainerWidth(containerRef.current?.clientWidth ?? 0);
  }, []);

  function handleClickButton(type: 'prev' | 'next') {
    return () => {
      if (type === 'prev') {
        swiper.slidePrev();
      } else {
        swiper.slideNext();
      }
    };
  }

  return (
    <S.BannerContainer ref={containerRef} $width={containerWidth}>
      <Swiper
        onSwiper={setSwiper}
        slidesPerView='auto'
        loop
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        onSlideChange={(swiper: SwiperClass) =>
          setCurrentIndex(swiper.realIndex)
        }
        modules={[Autoplay]}
        spaceBetween={16}
        style={{ width: '100%' }}
      >
        {banners?.map((banner) => (
          <SwiperSlide key={banner.title}>
            <LoggingClick
              logName='hh_hub_Carousel_click'
              data={{
                banner_title: banner.title,
                banner_link: banner.link,
              }}
            >
              <CustomLink href={banner.link}>
                <S.BannerContent backgroundColor={banner.backgroundColor}>
                  <S.BannerText>
                    <Text
                      as='span'
                      mFont='mBody1'
                      wFont='wTitle1'
                      color='neutralDay.white'
                    >
                      {convertNewlineToJSX(banner.title)}
                    </Text>
                    <Flex align='center'>
                      <Text
                        as='span'
                        mFont='mCaption2'
                        wFont='wBody2'
                        color={applyOpacityToHexColor(neutralDay.white, 0.8)}
                      >
                        {banner.subTitle}
                      </Text>
                      <S.DesktopOnly>
                        <ArrowChevronRightSmall
                          size={20}
                          color={applyOpacityToHexColor(neutralDay.white, 0.8)}
                        />
                      </S.DesktopOnly>
                    </Flex>
                  </S.BannerText>
                  <S.MobileOnly>
                    <S.BannerImageWrapper>
                      <Image
                        src={banner.mobileImageUrl}
                        alt={banner.title}
                        width={140}
                        height={122}
                      />
                    </S.BannerImageWrapper>
                  </S.MobileOnly>
                  <S.DesktopOnly>
                    <S.BannerImageWrapper>
                      <Image
                        src={banner.desktopImageUrl}
                        alt={banner.title}
                        width={400}
                        height={200}
                      />
                    </S.BannerImageWrapper>
                  </S.DesktopOnly>
                </S.BannerContent>
              </CustomLink>
            </LoggingClick>
          </SwiperSlide>
        ))}
      </Swiper>
      <S.BannerControls>
        <LoggingClick
          logName='hh_hub_Carousel_click'
          data={{
            controller: '왼쪽 화살표',
          }}
        >
          <S.BannerControlButton onClick={handleClickButton('prev')}>
            <ArrowChevronLeftSmall size={16} color={neutralDay.white} />
          </S.BannerControlButton>
        </LoggingClick>
        <Flex align='center' gap={3}>
          <Text as='span' mFont='mCaption2' color='neutralDay.white'>
            {currentIndex + 1}
          </Text>
          <Text
            as='span'
            mFont='mCaption2'
            color={applyOpacityToHexColor(neutralDay.white, 0.6)}
          >
            /
          </Text>
          <Text
            as='span'
            mFont='mCaption2'
            color={applyOpacityToHexColor(neutralDay.white, 0.6)}
          >
            {banners?.length}
          </Text>
        </Flex>
        <LoggingClick
          logName='hh_hub_Carousel_click'
          data={{ controller: '오른쪽 화살표' }}
        >
          <S.BannerControlButton onClick={handleClickButton('next')}>
            <ArrowChevronRightSmall
              size={16}
              color={applyOpacityToHexColor(neutralDay.white, 0.8)}
            />
          </S.BannerControlButton>
        </LoggingClick>
      </S.BannerControls>
    </S.BannerContainer>
  );
}

Banner.Skeleton = BannerSkeleton;

function BannerSkeleton() {
  const [containerWidth, setContainerWidth] = useState(0);

  const containerRef = useResizeObserver({
    onResize: (entry) => {
      setContainerWidth(entry.contentRect.width);
    },
  });

  useIsomorphicLayoutEffect(() => {
    setContainerWidth(containerRef.current?.clientWidth ?? 0);
  }, []);
  return (
    <S.BannerContainer ref={containerRef} $width={containerWidth}>
      <S.BannerContent backgroundColor={neutralDay.gray95}>
        <S.BannerText>
          <Text
            as='span'
            mFont='mBody1'
            style={{
              borderRadius: '6px',
              backgroundColor: neutralDay.gray90,
              width: '200px',
            }}
          >
            &nbsp;
            <br />
            &nbsp;
          </Text>
          <Text
            as='span'
            mFont='mCaption2'
            style={{
              borderRadius: '6px',
              backgroundColor: neutralDay.gray90,
              width: '150px',
            }}
          >
            &nbsp;
          </Text>
        </S.BannerText>
      </S.BannerContent>
    </S.BannerContainer>
  );
}
