import styled from '@emotion/styled';
import { neutralDark } from '@teamsparta/design-system';

import { DESKTOP } from '@/styles/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  background-color: ${neutralDark.white};

  ${DESKTOP} {
    padding-top: 60px;
  }
`;

export const HubImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 260px;

  ${DESKTOP} {
    height: 360px;
  }
`;

export const HeroTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${DESKTOP} {
    gap: 16px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 100%;
  max-width: 820px;
  margin: 0 auto;
  padding-top: 60px;
  padding-inline: 16px;
  gap: 40px;

  ${DESKTOP} {
    padding-top: 100px;
    padding-bottom: 40px;
    padding-inline: 0;
    gap: 80px;
  }
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${DESKTOP} {
    gap: 60px;
  }
`;
