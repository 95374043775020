import styled from '@emotion/styled';

import { DESKTOP } from '@/styles/themes';

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 420/258;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.08);

  ${DESKTOP} {
    border-radius: 12px;
    width: 420px;
    height: 258px;
  }
`;

export const MobileCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DifferenceCardListWrapper = styled.div`
  .swiper {
    overflow: visible !important;
  }

  .swiper-slide {
    width: 280px;
  }
`;
