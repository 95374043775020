import { ReactNode } from 'react';

import * as S from './style';

interface CardProps {
  title: ReactNode;
  content: ReactNode;
  bottomText: ReactNode;
  topAddon?: ReactNode;
}

export function Card({ topAddon, bottomText, title, content }: CardProps) {
  return (
    <S.ReviewCardContainer>
      {topAddon}
      <S.ReviewCardContentContainer>
        {title}
        <S.ReviewContentWrapper>{content}</S.ReviewContentWrapper>
      </S.ReviewCardContentContainer>
      {bottomText}
    </S.ReviewCardContainer>
  );
}
