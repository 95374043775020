import styled from '@emotion/styled';
import {
  hanghae,
  neutralDark,
  neutralDay,
  wHeader2,
} from '@teamsparta/design-system';

import { DESKTOP } from '@/styles/themes';

export const Wrapper = styled.div`
  padding: 80px 16px;
  background-color: ${neutralDark.white};

  ${DESKTOP} {
    padding: 100px 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 820px;
  width: 100%;
  margin: 0 auto;

  ${DESKTOP} {
    gap: 60px;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(280px, 1fr));
  gap: 16px;

  ${DESKTOP} {
    grid-template-columns: repeat(2, minmax(280px, 1fr));
    gap: 20px;
  }
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const Card = styled.div<{ isDisabled?: boolean }>`
  position: relative;
  width: 100%;
  padding: 32px 24px;
  border-radius: 8px;
  background-color: ${neutralDay.gray95};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
  transition: transform 0.3s ease;

  ${DESKTOP} {
    border-radius: 12px;
    padding: 32px 40px;
    max-width: 400px;
    &:hover {
      transform: ${({ isDisabled }) =>
        isDisabled ? 'none' : 'translateY(-8px)'};
    }
  }
`;

export const CardArrow = styled.div`
  position: absolute;
  top: 62px;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background: ${hanghae.red100};
  border-radius: 8px;

  ${DESKTOP} {
    top: 70.5px;
    right: 40px;
    width: 44px;
    height: 44px;
    ${wHeader2};
  }
`;
