import styled from '@emotion/styled';
import { neutralDay } from '@teamsparta/design-system';

import { DESKTOP } from '@/styles/themes';

export const Wrapper = styled.div`
  padding: 80px 16px;
  background-color: ${neutralDay.gray95};

  ${DESKTOP} {
    padding: 100px 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 820px;
  width: 100%;
  margin: 0 auto;

  ${DESKTOP} {
    gap: 60px;
  }
`;

export const TextButton = styled.button`
  all: unset;
  cursor: pointer;
`;

export const MobileOnly = styled.div`
  display: contents;

  ${DESKTOP} {
    display: none;
  }
`;

export const DesktopOnly = styled.div`
  display: none;

  ${DESKTOP} {
    display: contents;
  }
`;

export const PostWrapper = styled.div`
  .swiper {
    overflow: visible !important;
  }

  .swiper-slide {
    width: 260px;
  }
`;

export const MoreButton = styled.button`
  display: flex;
  min-width: 84px;
  width: 100%;
  padding: 12px 24px 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${neutralDay.gray80};
  background: ${neutralDay.gray90};
`;
