import styled from '@emotion/styled';

import { device } from '@/styles/themes';

export const ChannelTalkButton = styled.button<{
  bottom: number;
}>`
  border: none;
  background-color: transparent;
  position: fixed;
  bottom: ${(props) => props.bottom}px;
  right: 5%;
  cursor: pointer;
  width: 80px;
  height: 80px;
  z-index: 19;

  @media ${device.desktop} {
    right: 20px;
  }
`;
