import styled from '@emotion/styled';
import { neutralDay } from '@teamsparta/design-system';

import { DESKTOP } from '@/styles/themes';

export const StatisticContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${DESKTOP} {
    gap: 0;
  }
`;

export const StatisticCount = styled.span`
  font-family: Pretendard, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 39px;
  -webkit-text-stroke: 1px ${neutralDay.white};
  color: transparent;

  ${DESKTOP} {
    font-size: 48px;
    line-height: 64px;
  }
`;

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 12px;
  height: 12px;

  ${DESKTOP} {
    width: 19px;
    height: 19px;
  }
`;
