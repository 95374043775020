import { useIsDesktop } from '@/hooks/common';

import { Review } from './ReviewsView.data';

export function removeFirstChar(str: string) {
  return str.slice(1);
}

export function removeTrailingChar(str: string, char: string) {
  if (str.endsWith(char)) {
    return str.slice(0, -1);
  }
  return str;
}

function removeTrailingDot(str: string): string {
  return removeTrailingChar(str, '.');
}

export function addEllipsis(comment: string, maxLength: number): string {
  if (comment.length > maxLength) {
    const trimmed = removeTrailingDot(comment.slice(0, maxLength).trim());
    return `${trimmed}...`;
  }

  return `${removeTrailingDot(comment.trim())}...`;
}

export function isMobileList(list: Review[] | Review[][]): list is Review[] {
  return !Array.isArray(list[0]);
}

export function isDesktopList(list: Review[] | Review[][]): list is Review[][] {
  return !isMobileList(list);
}

export function useReviewCardList(
  countToShowPlusReviewCard: number,
  reviews: Review[],
) {
  const isDesktop = useIsDesktop();

  const mobileCardList: Review[] = reviews.slice(0, countToShowPlusReviewCard);

  const desktopCardList: Review[][] = reviews
    .slice(0, countToShowPlusReviewCard)
    .reduce(
      (acc, cur, index) => {
        if (index % 2 === 0) {
          acc[0].push(cur);
        } else {
          acc[1].push(cur);
        }
        return acc;
      },
      [[], []] as Review[][],
    );

  const cardList: Review[] | Review[][] = isDesktop
    ? desktopCardList
    : mobileCardList;

  return { isDesktop, cardList };
}
