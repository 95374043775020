import styled from '@emotion/styled';
import { neutralDay } from '@teamsparta/design-system';

import { DESKTOP } from '@/styles/themes';

export const ReviewCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  width: 100%;
  border: 1px solid ${neutralDay.gray90};
  background: rgba(20, 22, 23, 0.32);
  backdrop-filter: blur(12px);
  border-radius: 12px;

  ${DESKTOP} {
    padding: 32px;
  }
`;

export const ReviewCardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${DESKTOP} {
    gap: 12px;
  }
`;

export const ReviewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
