import styled from '@emotion/styled';
import { neutralDark } from '@teamsparta/design-system';

import { DESKTOP } from '@/styles/themes';

export const Wrapper = styled.div`
  padding: 80px 16px;
  background-color: ${neutralDark.white};

  ${DESKTOP} {
    padding: 100px 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 820px;
  width: 100%;
  margin: 0 auto;

  ${DESKTOP} {
    gap: 60px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${DESKTOP} {
    flex-direction: row;
    gap: 40px;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 420/258;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.08);

  ${DESKTOP} {
    border-radius: 12px;
    width: 420px;
    height: 258px;
  }
`;

export const DesktopOnly = styled.div`
  display: none;

  ${DESKTOP} {
    display: contents;
  }
`;

export const MobileOnly = styled.div`
  display: contents;

  ${DESKTOP} {
    display: none;
  }
`;
