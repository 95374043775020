import styled from '@emotion/styled';

import { DESKTOP } from '@/styles/themes';

export const BannerContainer = styled.div<{
  $width: number;
}>`
  display: flex;
  position: relative;
  width: 100%;
  height: 122px;
  overflow: hidden;
  border-radius: 8px;

  .swiper {
    overflow: visible !important;
  }

  .swiper-slide {
    width: ${({ $width }) => $width}px;
    height: 100%;
  }

  ${DESKTOP} {
    height: 200px;
    border-radius: 12px;
  }
`;

export const BannerContent = styled.div<{
  backgroundColor?: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 16px;
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;

  ${DESKTOP} {
    padding: 32px 40px;
    border-radius: 12px;
  }
`;

export const BannerControls = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 3px 10px;
  right: 6px;
  bottom: 6px;
  background-color: rgba(20, 22, 23, 0.6);
  border-radius: 40px;
  gap: 2px;

  ${DESKTOP} {
    padding: 6px;
    right: 16px;
    bottom: 16px;
  }
`;

export const BannerControlButton = styled.button`
  all: unset;
  display: none;
  ${DESKTOP} {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

export const BannerText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  white-space: pre-wrap;
`;

export const BannerImageWrapper = styled.div`
  position: relative;
  height: 100%;

  ${DESKTOP} {
    height: auto;
    max-height: 200px;
    margin-right: -40px;
  }
`;

export const DesktopOnly = styled.div`
  display: none;

  ${DESKTOP} {
    display: contents;
  }
`;

export const MobileOnly = styled.div`
  display: contents;

  ${DESKTOP} {
    display: none;
  }
`;
