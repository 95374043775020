import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/react';

import CustomLink from '@/components/common/CustomLink';
import { LoggingClick } from '@/components/common/LoggingClick';
import { type ICourseCard } from '@/pages/hub/components/Hero/data';

import { CourseCard } from '../CourseCard';
import * as S from './style';

interface CourseCardListProps {
  courseCards: ICourseCard[];
}

export function CourseCardList({ courseCards }: CourseCardListProps) {
  return (
    <S.CardListWrapper>
      <S.CardListContainer>
        <Swiper slidesPerView='auto' style={{ width: '100%' }}>
          {courseCards?.map((card) => (
            <SwiperSlide key={card.title}>
              <LoggingClick
                logName='hh_hub_HeroCourseCard_click'
                data={{ course_title: card.title }}
              >
                <CustomLink href={card.link}>
                  <CourseCard {...card} />
                </CustomLink>
              </LoggingClick>
            </SwiperSlide>
          ))}
        </Swiper>
      </S.CardListContainer>
    </S.CardListWrapper>
  );
}

CourseCardList.Skeleton = CourseCardListSkeleton;

function CourseCardListSkeleton() {
  return (
    <S.CardListWrapper>
      <S.CardListContainer>
        <Swiper
          slidesPerView='auto'
          spaceBetween={16}
          style={{ width: '100%' }}
        >
          {Array(5)
            .fill(0)
            .map((_, index) => (
              <SwiperSlide key={index}>
                <CourseCard.Skeleton />
              </SwiperSlide>
            ))}
        </Swiper>
      </S.CardListContainer>
    </S.CardListWrapper>
  );
}
