import { useState } from 'react';

import { useTimer } from '@/hooks/common';

import { differenceData } from './data';

export function useDifference() {
  const [activeIndex, setActiveIndex] = useState(0);
  const { resetTimer, startTimer } = useTimer({
    expireTime: 4.5,
    onExpire: () => {
      resetTimer();
      setActiveIndex((prev) => (prev + 1) % 3);
    },
    onReset: () => {
      startTimer();
    },
    interval: 100,
    decrementValue: 0.1,
  });

  function onClick(index: number) {
    if (index === activeIndex) {
      return;
    }
    resetTimer();
    setActiveIndex(index);
  }

  return { startTimer, activeIndex, onClick, data: differenceData };
}
