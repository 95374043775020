import styled from '@emotion/styled';
import { neutralDark } from '@teamsparta/design-system';

import { DESKTOP } from '@/styles/themes';

export const Wrapper = styled.div`
  padding: 80px 16px;
  background-color: ${neutralDark.white};

  ${DESKTOP} {
    padding: 100px 0;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 820px;
  width: 100%;
  margin: 0 auto;

  ${DESKTOP} {
    gap: 60px;
  }
`;

export const ReviewTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  z-index: 1;

  ${DESKTOP} {
    gap: 32px;
  }
`;

export const StatisticsContainer = styled.div`
  display: flex;
  gap: 20px;

  ${DESKTOP} {
    gap: 87px;
  }
`;

export const ReviewVideo = styled.video`
  width: 100%;
`;

export const ReviewVideoWrapper = styled.div`
  position: absolute;
  width: 100%;
  max-width: 820px;
  margin-top: -80px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        90deg,
        #141617 0%,
        rgba(20, 22, 23, 0.54) 40.5%,
        rgba(20, 22, 23, 0.06) 57.5%,
        rgba(20, 22, 23, 0) 71.61%,
        rgba(20, 22, 23, 0.1) 78.5%,
        rgba(20, 22, 23, 0.37) 87.5%,
        #141617 100%
      ),
      linear-gradient(180deg, rgba(20, 22, 23, 0.48) 0%, #141617 100%);
  }

  ${DESKTOP} {
    margin-top: -100px;
  }
`;

export const ReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .swiper {
    overflow: visible !important;
  }

  .swiper-slide {
    width: 398px;
  }

  ${DESKTOP} {
    flex-direction: row;
    gap: 20px;
  }
`;

export const MobileOnly = styled.div`
  display: contents;

  ${DESKTOP} {
    display: none;
  }
`;

export const DesktopOnly = styled.div`
  display: none;

  ${DESKTOP} {
    display: contents;
  }
`;
