import Link, { type LinkProps } from 'next/link';
import {
  type AnchorHTMLAttributes,
  forwardRef,
  type PropsWithChildren,
} from 'react';

type CustomLinkProps = PropsWithChildren<
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> & LinkProps
>;

/**
 * 커스텀 링크
 * href 가 http로 시작하면 새 창에서 열립니다.
 * href 가 #으로 시작하면 id를 찾아갑니다.
 * 이외는 Next Link를 이용합니다.
 */
const CustomLink = forwardRef<HTMLAnchorElement, CustomLinkProps>(
  function _CustomLink({ href = '', children, ...restProps }, ref) {
    const hrefString = href?.toString();

    if (hrefString?.startsWith('http'))
      return (
        <a
          ref={ref}
          href={hrefString}
          {...restProps}
          target='_blank'
          rel='noreferrer noopener'
        >
          {children}
        </a>
      );

    if (hrefString?.startsWith('#'))
      return (
        <a ref={ref} href={hrefString} {...restProps}>
          {children}
        </a>
      );

    return (
      <Link ref={ref} href={hrefString} {...restProps}>
        {children}
      </Link>
    );
  },
);

export default CustomLink;
