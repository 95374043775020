import { Text } from '@teamsparta/design-system';
import Image from 'next/image';

import { Flex } from '@teamsparta/design-system';

import * as S from './style';

interface StatisticsDisplayProps {
  increase: number;
  title: string;
}

export function StatisticsDisplay({ increase, title }: StatisticsDisplayProps) {
  return (
    <S.StatisticContainer>
      <Flex align='center' gap={8}>
        <S.StatisticCount>{increase.toLocaleString()}</S.StatisticCount>
        <S.IconWrapper>
          <Image src='/images/icons/red_plus.svg' alt='' fill />
        </S.IconWrapper>
      </Flex>
      <Text
        as='span'
        mFont='mCaption2'
        wFont='wBody4'
        color='neutralDay.gray60'
      >
        {title}
      </Text>
    </S.StatisticContainer>
  );
}
