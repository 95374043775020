import styled from '@emotion/styled';

import { DESKTOP } from '@/styles/themes';

export const CardContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 150px;
  aspect-ratio: 148/190;
  flex-shrink: 0;
  padding: 20px 16px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.12);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);

  ${DESKTOP} {
    width: 224px;
    border-radius: 12px;
    padding: 32px 24px;
  }
`;

export const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  gap: 12px;

  ${DESKTOP} {
    gap: 16px;
  }
`;

export const CardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CardBadge = styled.div<{
  backgroundColor?: string;
}>`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 4px;
  padding: 2px 4px;

  ${DESKTOP} {
    padding: 4px 8px;
    border-radius: 6px;
  }
`;
