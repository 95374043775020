import 'swiper/css';

import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Accordion } from '../Accordion/Accordion';
import * as S from './style';

interface DifferenceCardListProps {
  differences: {
    title: string;
    content: string;
    imageUrl: string;
  }[];
}

export function MobileDifferenceCardList({
  differences,
}: DifferenceCardListProps) {
  return (
    <S.DifferenceCardListWrapper>
      <Swiper slidesPerView='auto' spaceBetween={20} style={{ width: '100%' }}>
        {differences?.map(({ title, content, imageUrl }) => (
          <SwiperSlide key={title}>
            <S.MobileCardContainer>
              <S.ImageWrapper>
                <Image
                  //** CDN 이미지 연동 -> public 이미지로 임시 처리 */
                  // src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}${imageUrl}`}
                  src={imageUrl}
                  alt={title}
                  fill
                />
              </S.ImageWrapper>
              <Accordion title={title} content={content} open />
            </S.MobileCardContainer>
          </SwiperSlide>
        ))}
      </Swiper>
    </S.DifferenceCardListWrapper>
  );
}
