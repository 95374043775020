import styled from '@emotion/styled';
import { neutralDay } from '@teamsparta/design-system';

export const MoreButton = styled.span`
  margin-left: 4px;
  font-family: Pretendard, sans-serif;
  font-size: 13px;
  font-weight: 500;
  text-decoration-line: underline;
  color: ${neutralDay.gray10};
  cursor: pointer;
`;

export const BottomTextDivider = styled.span`
  width: 1px;
  height: 10px;
  background-color: ${neutralDay.gray80};
`;
